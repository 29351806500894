<template>
	<div>
		<div class="plan_top">
			<div class="plan_title">保障计划</div>
			<div class="color_main" @click="moreDetail">更多详情 <span class="arrow_down_blue"></span></div>
		</div>
		<ul>
			<li class="li_row">
				<span class="fw_500">方案选择</span>
				<div class="btn_box">
					<div v-for="(item, index) in socialOptions" :key="index" :class="['btn', { active: socialInsuFlag == item.value }]" @click="handleTab(item)">{{ item.label }}</div>
				</div>
			</li>
			<li class="li_row" v-for="(item, index) in planList" :key="index">
				<div class="li_left">
					{{ item.contentName }}
					<div class="li_content">{{ item.contentContent }}</div>
				</div>
				<div>{{ item.price }}</div>
			</li>
			<li class="li_row f_22">
				<div>保费与保障年龄、有无社保有关，<span class="color_main" @click="handleDetail">查看费率明细、</span><span class="color_main" @click="handleDisclaimer">免责条款</span></div>
			</li>
		</ul>
		<bottom-info-pop v-model="infoPopShow" :title="infoPopTitle" :content="preventDuty"></bottom-info-pop>

	</div>
</template>

<script>
export default {
	props: {
		planList: {
			type: Array,
			default: ()=>[]
		},
		productDetail:{
			type:Object,
			default:()=>{}
		},
		insureInfo:{
			type:Object,
			default:()=>{}
		},
		socialOptions:{
			type:Array,
			default:()=>[]
		},
		preventDuty:{
			type:String,
			default:""
		}
	},
	data() {
		return {
			socialInsuFlag: true, //是否有社保
			infoPopShow:false,
			infoPopTitle:"免责条款",
			
		};
	},
	methods: {
		handleTab(item) {
			this.socialInsuFlag = item.value;
			this.$emit('handleSocial', this.socialInsuFlag);
		},
		// 更多详情
		moreDetail() {
			const {product,information}=this.productDetail
			const {bookList,productFileServer}=this.insureInfo
			const healthLink=bookList.filter(item=>item.docType==='08')[0]?.docLink
			this.$router.push({
				name: 'health_more_detail',
				params:{productDetail:{...product,compensationHint:information.compensationHint,link:productFileServer+healthLink}}
			});
		},
		// 费率明细
		handleDetail() {
			const {product,information}=this.productDetail

			this.$router.push({
				name: 'health_rate_detail',
				params:{detail:{imgSrc:product.featureThree,hint:information.hint}}
				
			});
		},
		// 免责条款
		handleDisclaimer(){
			this.infoPopShow=true
			
		}
	},
};
</script>

<style lang="scss" scoped>
.plan_top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 0.24rem;
	padding-top: 0.28rem;
	.plan_title {
		font-size: 0.36rem;
		font-weight: 700;
		color: #3d3d3d;
	}
	.arrow_down_blue {
		display: inline-block;
		width: 0.24rem;
		height: 0.2rem;
		background-image: url(../../../../assets/icon/arrow_right_blue.svg);
		background-repeat: no-repeat;
		background-size: 100%;
	}
}
.color_main {
	color: $b_color_main;
}
.li_row {
	display: flex;
	font-size: 0.28rem;
	justify-content: space-between;
	align-items: center;
	border-bottom: 0.02rem solid $b_border_color;
	padding: 0.24rem 0;
	color: #3d3d3d;
	.btn_box {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	.btn {
		width: 1.6rem;
		height: 0.6rem;
		background: $b_border_color;
		color: #3d3d3d;
		border-radius: 0.12rem 0.12rem 0.12rem 0.12rem;
		font-size: 0.28rem;
		text-align: center;
		line-height: 0.6rem;
		margin-left: 0.24rem;
	}

	.active {
		color: #fff;
		background: #409eff;
	}
	.li_left {
		width: 4.46rem;
	}
	.li_content {
		font-size: 0.22rem;
	}
	&:last-child {
		border: 0;
	}
}

.fw_500 {
	font-weight: 500;
}
.f_22 {
	font-size: 0.22rem;
}
</style>
