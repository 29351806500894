<template>
	<div class="detail_box" v-if="showDetail">
		<top-nav :showBack="false" bgColor="#fff"><span class="title">保障领取登记</span></top-nav>
		<!-- banner -->
		<div class="banner" v-if="product.proPicUrlIndexBig">
			<img :src="product.proPicUrlIndexBig" />
		</div>
		<!-- 产品标题介绍 -->
		<div class="product_info">
			<div class="product_title">{{ product.name }}</div>
			<div class="product_age">
				<div>保障年龄：{{ product.insureAge }}</div>
				<div class="line"></div>
				<div>保障期限：{{ product.insureTime }}</div>
				<div class="line"></div>
				<div>保障职业：1-4类</div>
			</div>
		</div>
		<!-- 保障计划 -->
		<div class="content_box padding_rl_24">
			<productPlanVue @handleSocial="handleSocial" :planList="planList" :productDetail="productDetail" :insureInfo="insureInfo" :socialOptions="socialOptions" :preventDuty="insProductInformation2Vo.preventDuty"/>
		</div>
		<!--投保信息  -->
		<div class="content_box padding_rl_24 mt_2">
			<div class="form_title">填写投保信息</div>
			<!-- 起始时间 -->
			<formGroupItem ref="timeForm" :form-option="timeFormOption" :rules="{}" v-model="timeForm"></formGroupItem>

			<!-- 投保信息 -->
			<div class="insure">
				<div class="insure-title">
					<span class="insure-radius">1</span>
					<span>谁付钱（投保人）</span>
				</div>
				<formGroupItem ref="insureForm" :form-option="formOption" :rules="rule" v-model="form"></formGroupItem>
			</div>
			<!--  被保人-->
			<div class="insure">
				<div class="insure-title">
					<span class="insure-radius">2</span>
					<span>为谁投保（被保险人｜{{socialName}}）</span>
				</div>
				<div class="popup_tip">父母或子女，限定为有血缘关系或有法律收养关系</div>
				<div class="popup_form">
					<formGroupItem ref="assuredForm" :form-option="assuredFormOption" :rules="assuredRule" v-model="assuredForm">
						<template #relation>
							<div class="relation_box">
								<!-- 单选框 -->
								<van-radio-group v-model="assuredForm.relation" class="relation" @change="handleChange">
									<van-radio v-for="v in relationOptions" :key="v.name" :name="v.name" :class="{ active: assuredForm.relation === v.name }">{{ v.label }}</van-radio>
								</van-radio-group>
							</div>
						</template>
					</formGroupItem>
				</div>
			</div>
		</div>
		<!-- 产品特色 -->
		<div class="content_box mt_2">
			<div class="info-img">
				<img :src="product.productDescribe" alt="" />
			</div>
		</div>
		<!-- 声明 -->
		<div class="read-trem">
			<readTrems v-show="showRead" @getReadOk="getReadOk" :proId="proId" :insProductInformation2Vo="insProductInformation2Vo" :comboId="comboId"/>
		</div>
		<!-- 立即购买 -->
		<price-buy-btn :price="price" @buy="buy"></price-buy-btn>
	</div>
</template>

<script>
import productPlanVue from './components/productPlan.vue';
import formGroupItem from '@/components/formGroupItem.vue';
import { productDetail, fixedInfo, http_getProductComboConfig, getInsuerInfo } from '@/request/api';
import moment from 'moment';
import Mtils from 'mtils';
import regular from '@/assets/js/regular';
import { Toast, Radio, RadioGroup } from 'vant';
import readTrems from '@/components/readTrems.vue';
import { http_getAreaList } from '@/request/common';
import { http_getInsProfessionCodeListByCompanyId } from '@/request/liability';
export default {
	name: 'healthDetail',
	components: {
		productPlanVue,
		formGroupItem,
		readTrems,
		[Toast.name]: Toast,
		[RadioGroup.name]: RadioGroup,
		[Radio.name]: Radio,
	},
	data() {
		return {
			price: 0,
			timeForm: {
				start: '',
			},
			timeFormOption: [],
			showDetail: false,

			form: {
				name: '',
				number: '',
				phone: '',
				area: '',
			},
			formOption: [
				{
					label: '姓名',
					prop: 'name',
				},
				{
					label: '身份证号码',
					prop: 'number',
				},
				{
					label: '手机号码',
					prop: 'phone',
				},
				{
					label: '居住地区',
					prop: 'area',
					type: 'cascader',
					options: [],
				},
			],
			rule: {
				name: [{ required: true }, { pattern: /^[a-zA-Z()（）·]+$|^[\u4e00-\u9fa5()（）·]+$/, message: '只能输入中文或英文以及()·' }, { pattern: /[a-zA-Z]{4,}|[\u4e00-\u9fa5]{2,}/, message: '至少2个中文或4个英文字母' }, { validator: this.nameCheck, message: '·不能出现在首尾' }],
				number: [{ required: true }, { validator: this.cardNumCheck, message: '身份证号码有误' },{validator:this.checkAge,message:"投保人需满18岁"}],
				phone: [{ required: true }, { validator: this.phoneCheck, message: '请填写正确的手机号码' }],
				area: [{ required: true }],
			},
			assuredRule: {
				name: [{ required: true }, { pattern: /^[a-zA-Z()（）·]+$|^[\u4e00-\u9fa5()（）·]+$/, message: '只能输入中文或英文以及()·' }, { pattern: /[a-zA-Z]{4,}|[\u4e00-\u9fa5]{2,}/, message: '至少2个中文或4个英文字母' }, { validator: this.nameCheck, message: '·不能出现在首尾' }],
				number: [{ required: true }, { validator: this.cardNumCheck, message: '身份证号码有误' }],
				phone: [{ required: true }, { validator: this.phoneCheck, message: '请填写正确的手机号码' }],
				area: [{ required: true }],
				career: [{ required: true }],
			},
			relationOptions: [
				{
					label: '本人',
					name: 1,
				},
				{
					label: '配偶',
					name: 2,
				},
				{
					label: '父母',
					name: 3,
				},
				{
					label: '子女',
					name: 4,
				},
				
				
			], //关系
			assuredForm: {
				name: '',
				number: '',
				area: '',
				career: '',
				relation: '',
			
			}, //被保人
			assuredFormOption: [
				{
					prop: 'relation',
					slot: true,
				},
				{
					label: '姓名',
					prop: 'name',
				},
				{
					label: '身份证号码',
					prop: 'number',
				},
				{
					label: '手机号',
					prop: 'phone',
				},
				{
					label: '居住地区',
					prop: 'area',
					type: 'cascader',
					options: this.areaList,
				},
				{
					label: '职业',
					prop: 'career',
					type: 'cascader',
					cascaderShow: 'last',
					options: this.careerList,
				},
			],

			hasSocial: 1, //是否有社保
			insProductInformation2Vo: {
				f1FixedInfo: '', //投保声明,
				f3FixedInfo: '', //个人信息保护政策
				preventDuty: '', //免责内容
				f2FixedInfo: '', //被保险人同意声明
				hint: '', //投保提示
			}, //阅读条款
			socialOptions:[
				{ label: '有社保版', value: 1 },
				{ label: '无社保版', value: 0 },
			],
			careerList: [], //获取职业
			areaList: [], //获取
			product: {}, //商品信息
			productDetail: {}, //所有商品信息
			planList: [], //保障金额列表
			planInfo: [], //费率
			activePlan: {}, //命中的费率
			ReadOk: false,
			comboId: '',
			insureInfo: {},
			insureRelation:{1:"01",2:"10",3:"50",4:"40"},//被保人与投保人信息
			insureRelationName:{1:"本人",2:"配偶",3:"子女",4:"父母"},
			assuredRelation:{1:1,2:2,3:4,4:3}

		};
	},
	computed: {
		minDate() {
			return moment().add(1, 'days').format('YYYY-MM-DD');
		},
		proId() {
			return this.$store.state.productId;
		},
		socialName(){
			const label=this.socialOptions.filter(item=>Number(this.hasSocial)===item.value)[0].label
			return label
		},
		showRead(){
			let flag=1
			for(let i in this.assuredForm){
				if(!this.assuredForm[i]){
					flag=0
				}
			}
			return flag
		}
	},
	watch: {
		'assuredForm.number': {
			handler() {
				this.changePrice();
			},
		},
		'hasSocial': {
			handler() {
				this.changePrice();
			},
		},
	},
	
	async created() {
		this.timeForm.start = this.minDate;
		this.getData();
		this.getInsure();
		this.timeFormOption=[
				{
					label: '起保日期',
					prop: 'start',
					type: 'calendar',
					minDate: this.minDate,
				},
			]
	},
	methods: {
		// 获取详情数据
		async getData() {
			Toast.loading({
				duration: 10, // 持续展示 toast
				forbidClick: true,
				message: '加载中',
			});
			const res = await productDetail(this.proId);
			this.productDetail = res;
			this.product = res.product;
			this.planList = res.combos[0].contents;
			this.getPlan();
			Toast.clear();
			this.showDetail = true;
		},
		// 获取产品信息
		async getInsure() {
			const res = await getInsuerInfo(this.proId);
			this.insureInfo = res;
			this.getCareer();
			this.getAreaList();
			this.getRules();
		},
		// 获取计划费率
		async getPlan() {
			this.comboId = this.productDetail.combos[0].comboId;
			const planInfo = await http_getProductComboConfig({ proId: this.proId, comboId: this.comboId });
			this.planInfo = planInfo.insProductPrice2VoList;
		},
		// 是否是社保
		handleSocial(val) {
			this.hasSocial = val;
		},
		// 投保人名称检测
		nameCheck(val) {
			if (val[0] === '·' || val[val.length - 1] === '·') {
				return false;
			}
			return true;
		},
		// 身份证号校验
		cardNumCheck(val) {
			return Mtils.validation.isIdCard(val);
		},
		// 手机号码校验
		phoneCheck(val) {
			return regular.phone.test(val);
		},
		checkAge(val){
			const { age} = this.$base.getInfoFromIdNumber(val);
			return age>18

		},
		// 关系
		handleChange(val) {
			//  如果是本人并且不是编辑的时候自动带入投保人信息
			if (val === 1) {
				this.assuredForm.name = this.form.name;
				this.assuredForm.number = this.form.number;
				this.assuredForm.area = this.form.area;
				this.assuredForm.phone = this.form.phone;
			}
			
		},
		// 计算费率
		changePrice() {
			const { age, sex, birthday } = this.$base.getInfoFromIdNumber(this.assuredForm.number);
			const data = this.planInfo.filter(item => age <= item.ageBelong1 && age >= item.ageBelong && this.hasSocial == item.eleUse1)[0];
			this.price = data?.price ?? 0;
			this.assuredForm.sex = sex;
			this.assuredForm.birthday = birthday;
			this.activePlan = data;
		},
		// 阅读声明
		getReadOk(val) {
			this.ReadOk = val;
		},
		// 获取职业
		async getCareer() {
			const res = await http_getInsProfessionCodeListByCompanyId(this.insureInfo.insurer.companyId);
			this.careerList = this.getCascader(res, { name: 'profName', code: 'profCode', otherCode: 'codeData' });
			this.assuredFormOption.forEach(item => {
				if (item.prop === 'career') {
					item.options = this.careerList;
				}
			});
		},
		// 获取省市区
		async getAreaList() {
			const res = await http_getAreaList(this.insureInfo.insurer.insurerCode);
			this.areaList = this.getCascader(res[0].children, { name: 'areaName', code: 'areaCode' });
			this.formOption.forEach(item => {
				if (item.prop === 'area') {
					item.options = this.areaList;
				}
			});
			this.assuredFormOption.forEach(item => {
				if (item.prop === 'area') {
					item.options = this.areaList;
				}
			});
		},
		// 处理级联选择器
		getCascader(obj, fieldName) {
			// 检查对象是否为数组
			if (Array.isArray(obj)) {
				for (let i = 0; i < obj.length; i++) {
					obj[i] = this.getCascader(obj[i], fieldName);
				}
			} else if (typeof obj === 'object' && obj !== null) {
				for (let key in obj) {
					if (obj.hasOwnProperty(key)) {
						if (key === fieldName['name']) {
							if (fieldName['otherCode']) {
								obj['name'] = obj[key] + (obj[fieldName['otherCode']] ? `(${obj[fieldName['otherCode']]}类)` : '');
							} else {
								obj['name'] = obj[key];
							}

							delete obj[key];
						} else if (key === fieldName['code']) {
							obj['code'] = obj[key];
							delete obj[key];
						} else {
							obj[key] = this.getCascader(obj[key], fieldName);
						}
					}
				}
			}
			return obj; // 返回替换后的对象
		},

		//获取投保声明
		async getRules() {
			const res = await fixedInfo();
			const obj = {
				f1FixedInfo: '投保声明',
				f3FixedInfo: '个人信息保护政策', //个人信息保护政策
			};
			res.fixedInfo.forEach(item => {
				for (let i in obj) {
					if (item.typeDesc == obj[i]) {
						this.insProductInformation2Vo[i] = item.fixedInfo;
					}
				}
			});
			this.insProductInformation2Vo.preventDuty = this.insureInfo.information.preventDuty;
			this.insProductInformation2Vo.f2FixedInfo = this.insureInfo.information.insuredDeclare;
			this.insProductInformation2Vo.hint = this.insureInfo.information.insuranceTip;
		},
		// 立即购买
		async buy() {
			const time = await this.$refs.timeForm.handleData();
			const insure = await this.$refs.insureForm.handleData();
			const assured = await this.$refs.assuredForm.handleData();
			if (!assured.relation) {
				return Toast('请选择投保人与被保人关系');
			}
			if(!this.ReadOk){
				return Toast("请先勾选条款")
			}
			const send = { extentionList: [] };

			// 被投保人信息
			let workplace = '';
			const areaData = assured.area.split('/');
			areaData.forEach(item => {
				workplace += item.split('&')[0];
			});

			send.assuredList = [
				{
					name: assured.name,
					certificateContent: assured.number,
					sex: assured.sex,
					birthday: assured.birthday.replace(/\//g, '-'),
					certificateType: '01',
					workplace,
					mobile: assured.phone,
					province: areaData[0].split('&')[1],
					city: areaData[1].split('&')[1],
					address: areaData[2].split('&')[1],
					relation:this.insureRelation[assured.relation]
				},
			];

			//订单信息
			send.insBill = {
				enableDate: `${time.start} 00:00:00`,
				disEnableDate: `${moment(time.start).add(1, 'y').subtract(1, 'days').format('YYYY-MM-DD')} 23:59:59`,
				proId: this.proId,
				platform: 'H5',
				insureType: 2,
			};
			const { sex, birthday } = this.$base.getInfoFromIdNumber(insure.number);
			
			// 投保人信息
			send.insurer = {
				insPhone: insure.phone,
				insName: insure.name,
				insCredentials: insure.number,
				insCredentialsType: '01',
				insBirthday: birthday.replace(/\//g, '-'),
				insSex: sex,
			};
			if(assured.relation===1){
				send.insurer.insProvince=areaData[0].split('&')[1]
				send.insurer.insCity=areaData[1].split('&')[1]
				send.insurer.insAddress=areaData[2].split('&')[1]
				send.insurer.insWorkplace=workplace
			}
			

			send.productPriceVo = {
				proId: this.proId,
				priceId: this.activePlan?.priceId,
				comboId: this.comboId,
				viewTime: this.activePlan?.viewTime,
				insureTime: this.activePlan?.insureTime,
				insureTimeUnit: this.activePlan?.insureTimeUnit,
				eleUse1: this.activePlan?.eleUse1,
				eleView1: this.activePlan?.eleView1,
				viewAgeBelong: this.activePlan?.viewAgeBelong,
			};
			if (insure.area) {
				const areaData = insure.area.split('/');
				let workplace = '';
				areaData.forEach(item => {
					workplace += item.split('&')[0];
				});
				send.extentionList = send.extentionList.concat([
					{ colName: 'livingArea', colValue: workplace },
					{ colName: 'provinceCode', colValue: areaData[0].split('&')[1] },
					{ colName: 'cityCode', colValue: areaData[1].split('&')[1] },
					{ colName: 'districtCode', colValue: areaData[2].split('&')[1] },
				]);
			}
			const workData = assured.career.split('/');
			let workType = workData[0];
			send.extentionList = send.extentionList.concat({ colName: 'socialInsuFlag', colValue: this.hasSocial }, { colName: 'relationToMainInsured', colValue: this.assuredRelation[assured.relation]}, { colName: 'relationToMainInsuredName', colValue: this.insureRelationName[assured.relation] }, { colName: 'workTypeSmallClass', colValue: workType });
			// 跳转到健康告知页面
			const data = this.productDetail.information.productIntroduce.split('&amp;&amp;');
			this.$router.push({
				name: 'health_inform',
				params: {
					data: [
						{
							title: '投保人确认被保险人是否有以下情况？',
							content: data[0],
						},
						{
							title: '声明',
							content: data[1],
						},
						{
							title: '高危职业表',
							content: data[2],
						},
						{
							title: '比赛定义',
							content: data[3],
						},
					],
					orderData: send,
				},
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.detail_box {
	padding-bottom: 2.2rem;
}
.title {
	color: #000 !important;
}
.banner {
	width: 100%;
	height: 4.58rem;
	img {
		width: 100%;
		height: 100%;
	}
}
.product_info {
	padding: 0.21rem 0.42rem 0.42rem;
	.product_title {
		font-size: 0.31rem;
		color: #3d3d3d;
		line-height: 0.48rem;
	}
	.product_age {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-size: 0.2rem;
		color: #3d3d3d;
		margin-top: 0.21rem;
	}
}
.content_box {
	width: 6.7rem;
	background: #ffffff;
	box-shadow: 0rem 0.04rem 0.16rem 0rem rgba(0, 0, 0, 0.2);
	border-radius: 0.2rem 0.2rem 0.2rem 0.2rem;
	margin: 0 auto;
	box-sizing: border-box;
	overflow: hidden;
}
.padding_rl_24{
	padding: 0 0.24rem 0;
}
.form_title {
	font-weight: 700;
	font-size: 0.36rem;
	color: #3d3d3d;
	border-bottom: 0.02rem solid #e8eaec;
	padding: 0.28rem 0 0.2rem;
}
.mt_2 {
	margin-top: 0.2rem;
}
::v-deep .van-cell {
	padding: 0.24rem 0;
	border-bottom: 0.02rem solid #e8eaec;
}
::v-deep .cell_title {
	color: #3d3d3d;
}

.insure {
	.insure-title {
		font-size: 0.28rem;
		font-weight: 500;
		margin-top: 0.3rem;

		.insure-radius {
			display: inline-block;
			width: 0.34rem;
			height: 0.34rem;
			background: $b_color_main;
			color: #fff;
			text-align: center;
			line-height: 0.36rem;
			border-radius: 50%;
			font-size: 0.24rem;
			margin-right: 0.2rem;
		}
	}
}
.line {
	width: 1px;
	height: 0.2rem;
	background-color: #3d3d3d;
	display: inline-block;
	margin: 0 0.1rem;
}
.info-line {
	display: inline-block;
	width: 0.1rem;
	height: 0.32rem;
	background: #409eff;
	margin-right: 0.2rem;
}
.info-title {
	font-size: 0.36rem;
	font-weight: 700;
	color: #3d3d3d;
	padding-top: 0.28rem;
}
.info-img {
	width: 100%;
	img {
		width: 100%;
	}
}
.read-trem {
	width: 100%;
	position: fixed;
	left: 0;
	background: #fff;
	bottom: 1.1rem;
	z-index: 3001;
	::v-deep .checkbox {
		margin: 0.2rem 0 0.2rem 0.4rem;
	}
}
.color_main {
	color: $b_color_main;
}

.popup_title {
	font-size: 0.32rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.title {
		font-size: 0.36rem;
		font-weight: 500;
	}
}
.popup_tip {
	font-size: 0.24rem;
	color: #bbbbbb;
	text-align: center;
	margin-top: 0.2rem ;
}

.relation_box {
	width: 100%;
	
}
.relation {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;

	.van-radio {
		width: 1.35rem;
		height: 0.62rem;
		background: #e8eaec;
		border-radius: 0.12rem 0.12rem 0.12rem 0.12rem;
		justify-content: center;
		flex-shrink: 0;
		margin-right: 0.2rem;
		margin-top: 0.2rem;
		box-sizing: border-box;
	}
	.active {
		background: rgba(64, 158, 255, 0.1);
		box-sizing: border-box;
		border-radius: 0.12rem 0.12rem 0.12rem 0.12rem;
		border: 0.02rem solid #409eff;
		::v-deep .van-radio__label {
			color: $color_main;
		}
	}

	::v-deep .van-radio__icon .van-icon {
		display: none;
	}
	::v-deep .van-radio__label {
		margin-left: 0;
	}
}
</style>
